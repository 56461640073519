<template>
  <div>
    <page-content>
      <page-title heading="สรุปภาพรวมครูตำรวจแดร์">
        <!-- <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshing"
              size="sm"
              icon="sync"
              @click="refresh_user_rank"
            />
          </b-button>
        </template> -->
      </page-title>

      <chart-user-rank ref="user_rank" class="" />
    </page-content>

    <br />

    <page-content>
      <page-title heading="สรุปภาพรวมการสอน">
        <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshing"
              size="sm"
              icon="sync"
              @click="refresh"
            />
          </b-button>
        </template>
      </page-title>

      <chart-teaching-dare
        ref="chart"
        class="mb-5"
        @onFilterChanged="onFilterChanged"
      />
    </page-content>

    <br />

    <page-content>
      <page-title heading="สรุปภาพรวมการสมัครสมาชิกครู D.A.R.E">
        <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshingChartRegistration"
              size="sm"
              icon="sync"
              @click="refreshChartRegistration"
            />
          </b-button>
        </template>
      </page-title>

      <chart-registration ref="chartRegistration" class="mb-5" />
    </page-content>

    <br />

    <page-content>
      <page-title heading="สรุปภาพรวมผลการประเมิน">
        <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshing"
              size="sm"
              icon="sync"
              @click="refresh_survey"
            />
          </b-button>
        </template>
      </page-title>

      <chart-survey
        ref="chart_survey"
        class="mb-5"
        :formfilter.sync="filters"
      />
    </page-content>
  </div>
</template>

<script>
import PageContent from "../components/layout/PageContent";
import PageTitle from "../components/layout/PageTitle";
import ChartTeachingDare from "../components/chart/TeachingDare";
import ChartSurvey from "../components/chart/Survey";
import ChartUserRank from "../components/chart/UserRank";
import ChartRegistration from "../components/chart/Registration";

export default {
  components: {
    PageContent,
    PageTitle,
    ChartTeachingDare,
    ChartSurvey,
    ChartUserRank,
    ChartRegistration,
  },

  data() {
    return {
      isRefreshing: false,
      isRefreshingChartRegistration: false,
      filters: null,
    };
  },

  methods: {
    onFilterChanged(v) {
      this.filters = v;
    },

    refresh() {
      this.$nextTick(async () => {
        this.isRefreshing = true;

        await this.$refs.chart.fetch();

        setTimeout(() => {
          this.isRefreshing = false;
        }, 2000);
      });
    },

    refresh_survey() {
      this.$nextTick(async () => {
        this.isRefreshing = true;

        await this.$refs.chart_survey.fetch();

        setTimeout(() => {
          this.isRefreshing = false;
        }, 2000);
      });
    },

    refreshChartRegistration() {
      this.$nextTick(async () => {
        this.isRefreshingChartRegistration = true;

        await this.$refs.chartRegistration.fetch();

        setTimeout(() => {
          this.isRefreshingChartRegistration = false;
        }, 2000);
      });
    },

    // refresh_user_rank() {
    //   this.$nextTick(async () => {
    //     this.isRefreshing = true;

    //     await this.$refs.user_rank.fetch();

    //     setTimeout(() => {
    //       this.isRefreshing = false;
    //     }, 2000);
    //   });
    // },
  },
};
</script>
