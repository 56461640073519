<template>
  <b-card>
    <b-row v-if="isFetching">
      <b-col cols="12" class="mb-3">
        <b-skeleton-img no-aspect height="350px"></b-skeleton-img>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col v-if="!noData">
        <b-row>
          <b-col cols="12">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col v-else>
        <b-row align-v="center" align-h="center" style="height: 520px">
          ไม่พบข้อมูล
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { map, debounce } from "lodash";
import { Auth, Registration } from "../../models";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      isFetching: false,

      noData: false,

      chartData: [],
    };
  },

  watch: {
    // $route: {
    //   immediate: true,
    //   handler: "onRouteOrFilterChanged",
    // },
    // formfilter: {
    //   deep: true,
    //   handler: "onRouteOrFilterChanged",
    // },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    chartOptions() {
      const { chartData = [] } = this;

      return {
        chart: {
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#d94c53"],

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `${val} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        // title: {
        //   text: "ค่าตอบแทนการสอน",
        //   style: {
        //     fontSize: "14px",
        //     fontFamily: "Noto Sans Thai, sans-serif",
        //     colors: ["#333", "#333"],
        //   },
        // },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: [0, 10],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: chartData.map(({ month_year }) => month_year),

          title: {
            text: "เดือน/ปี",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวนผู้สมัครสมาชิก",
            rotate: 0,
            offsetY: -140,
            offsetX: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    series() {
      const { chartData = [] } = this;

      return [
        {
          name: "จำนวนผู้สมัครสมาชิก",
          data: map(chartData, "total_registrations").map((x) =>
            x ? parseInt(x) : 0
          ),
        },
      ];
    },
  },

  methods: {
    // async onRouteOrFilterChanged(v) {
    //   this.$emit("onFilterChanged", v);

    //   await this.fetch();
    // },

    async fetch() {
      let promise;
      let params = {};

      // const { mHeadquarterId, mDivisionId, mStationId, mSchoolId } =
      //   this.formfilter;

      this.noData = false;
      this.isFetching = true;

      // if (mHeadquarterId) {
      //   this.$set(params, "mHeadquarterId", mHeadquarterId);
      // }

      // if (mDivisionId) {
      //   this.$set(params, "mDivisionId", mDivisionId);
      // }

      // if (mStationId) {
      //   this.$set(params, "mStationId", mStationId);
      // }

      // if (mSchoolId) {
      //   this.$set(params, "mSchoolId", mSchoolId);
      // }

      try {
        promise = await Registration.api().getDashboard(params);

        const { data = [] } = promise.response.data;

        if (data.length) {
          this.chartData = [...data];
        } else {
          this.noData = true;
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงสรุปข้อมูลจำนวนนักเรียนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  async created() {
    this.fetch = debounce(this.fetch, 500);

    await this.fetch();
  },
};
</script>
