<template>
  <div>
    <b-row align-v="center" align-h="end" class="mb-4">
      <b-col cols="12">
        <!-- <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          col-headquarter="3"
          col-division="3"
          col-station="3"
          col-school="3"
          :hiddenInput="{ schoolClass: true }"
        >
        </filter-master> -->
      </b-col>
    </b-row>

    <b-row v-if="isFetching">
      <b-col cols="12" class="">
        <b-skeleton-img no-aspect height="400px"></b-skeleton-img>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col v-if="!noData">
        <b-card
          v-for="survey_answer in survey_answers"
          :key="survey_answer.id"
          class="mb-5"
        >
          <b-row>
            <h3 class="text-center col-12">
              <b> {{ survey_answer.survey_title }} </b>
            </h3>
            <div v-if="survey_answer.questions" class="row">
              <div
                v-for="question in survey_answer.questions"
                :key="question.id"
                class="mt-4"
                :class="
                  question.questions && question.questions[0]
                    ? 'col-12'
                    : 'col-12 col-md-6'
                "
              >
                <div
                  v-if="question.questions && question.questions[0]"
                  class="ml-4 row"
                >
                  <h5 class="mb-4 col-12">
                    {{ question.questions[0].survey_topic.survey_topic_title }}
                  </h5>
                  <div
                    v-for="isQuestionSurveyTopic in question.questions"
                    :key="isQuestionSurveyTopic.id"
                    class="col-6 py-3"
                  >
                    <div
                      v-if="isQuestionSurveyTopic.question_type == 'rating'"
                      class="mb-3 mx-4"
                    >
                      <h6 class="text__title">
                        {{ isQuestionSurveyTopic.question_title }}
                      </h6>

                      <div class="text-black-50 ml-3">
                        <p class="m-0">
                          ผู้ตอบจำนวน:
                          {{
                            isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                              ? isQuestionSurveyTopic.resAns[0].count_answer
                              : 0
                          }}
                          คน
                        </p>
                        <p class="m-0">
                          ค่าสถิติ:
                          {{
                            isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                              ? `${
                                  Math.round(
                                    isQuestionSurveyTopic.resAns[0].avg_rating *
                                      100
                                  ) / 100
                                } `
                              : "0"
                          }}
                        </p>
                        <p class="m-0">
                          ค่าสถิติร้อยละ:
                          {{
                            isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                              ? `${
                                  Math.round(
                                    (isQuestionSurveyTopic.resAns[0]
                                      .avg_rating /
                                      5) *
                                      100 *
                                      100
                                  ) / 100
                                } `
                              : "0"
                          }}
                        </p>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-12 col-md-6 col-xl-9 py-2">
                          <b-progress
                            height="23px"
                            :style="`background-color: ${genColor50(
                              isQuestionSurveyTopic.resAns &&
                                isQuestionSurveyTopic.resAns[0]
                                ? isQuestionSurveyTopic.resAns[0].avg_rating
                                : 0
                            )}`"
                          >
                            <b-progress-bar
                              :precision="2"
                              :value="
                                isQuestionSurveyTopic.resAns &&
                                isQuestionSurveyTopic.resAns[0]
                                  ? isQuestionSurveyTopic.resAns[0].avg_rating
                                  : 0
                              "
                              :max="5"
                              :style="`background-color: ${genColor(
                                isQuestionSurveyTopic.resAns &&
                                  isQuestionSurveyTopic.resAns[0]
                                  ? isQuestionSurveyTopic.resAns[0].avg_rating
                                  : 0
                              )}`"
                            ></b-progress-bar>
                          </b-progress>
                        </div>
                        <div
                          class="col-12 col-md-6 col-xl-3 py-2"
                          :style="`color: ${genColor(
                            isQuestionSurveyTopic.resAns &&
                              isQuestionSurveyTopic.resAns[0]
                              ? isQuestionSurveyTopic.resAns[0].avg_rating
                              : 0
                          )}`"
                        >
                          <h5 class="m-0">
                            {{
                              isQuestionSurveyTopic.resAns &&
                              isQuestionSurveyTopic.resAns[0]
                                ? `${
                                    Math.round(
                                      isQuestionSurveyTopic.resAns[0]
                                        .avg_rating * 100
                                    ) / 100
                                  } `
                                : "0"
                            }}
                          </h5>
                          <h5 class="m-0">คะแนน</h5>
                        </div>
                      </div>
                    </div>

                    <div v-else class="mb-3 ml-4">
                      <h6 class="text__title">
                        {{ isQuestionSurveyTopic.question_title }}
                      </h6>

                      <div></div>
                      <div id="chart">
                        <apexchart
                          type="donut"
                          width="340"
                          height="340"
                          :options="
                            chartOptions(isQuestionSurveyTopic.choice_detail)
                          "
                          :series="series(isQuestionSurveyTopic.choice_detail)"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="ml-4">
                  <h6 class="col-12 mb-3 text__title">
                    {{ question.question_title }}
                  </h6>
                  <div
                    v-if="question.question_type == 'rating'"
                    class="mb-3 ml-4"
                  >
                    <div class="text-black-50 ml-3">
                      <p class="m-0">
                        ผู้ตอบจำนวน:
                        {{
                          question.resAns && question.resAns[0]
                            ? question.resAns[0].count_answer
                            : 0
                        }}
                        คน
                      </p>
                      <p class="m-0">
                        ค่าสถิติ:
                        {{
                          question.resAns && question.resAns[0]
                            ? `${
                                Math.round(
                                  question.resAns[0].avg_rating * 100
                                ) / 100
                              } `
                            : "0"
                        }}
                      </p>
                      <p class="m-0">
                        ค่าสถิติร้อยละ:
                        {{
                          question.resAns && question.resAns[0]
                            ? `${
                                Math.round(
                                  (question.resAns[0].avg_rating / 5) *
                                    100 *
                                    100
                                ) / 100
                              } `
                            : "0"
                        }}
                      </p>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-10">
                        <b-progress
                          height="23px"
                          :style="`background-color: ${genColor50(
                            question.resAns && question.resAns[0]
                              ? question.resAns[0].avg_rating
                              : 0
                          )}`"
                        >
                          <b-progress-bar
                            :precision="2"
                            :value="
                              question.resAns && question.resAns[0]
                                ? question.resAns[0].avg_rating
                                : 0
                            "
                            :max="5"
                            :style="`background-color: ${genColor(
                              question.resAns && question.resAns[0]
                                ? question.resAns[0].avg_rating
                                : 0
                            )}`"
                          ></b-progress-bar>
                        </b-progress>
                      </div>
                      <div
                        class="col-2"
                        :style="`color: ${genColor(
                          question.resAns && question.resAns[0]
                            ? question.resAns[0].avg_rating
                            : 0
                        )}`"
                      >
                        <h5 class="m-0">
                          {{
                            question.resAns && question.resAns[0]
                              ? `${
                                  Math.round(
                                    question.resAns[0].avg_rating * 100
                                  ) / 100
                                } `
                              : "0"
                          }}
                        </h5>
                        <h5 class="m-0">คะแนน</h5>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mb-3 ml-4">
                    <div id="chart">
                      <apexchart
                        type="donut"
                        width="340"
                        height="340"
                        :options="chartOptions(question.choice_detail)"
                        :series="series(question.choice_detail)"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
        </b-card>
      </b-col>

      <b-col v-else>
        <b-row align-v="center" align-h="center" style="height: 520px">
          ไม่พบข้อมูล
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { debounce, mapKeys, chain } from "lodash";
import { Auth, Survey } from "../../models";
// import FilterMaster from "../form/FilterMaster";

export default {
  components: {
    apexchart: VueApexCharts,
    // FilterMaster,
  },

  props: {
    formfilter: {
      type: Object,
    },
  },

  data() {
    return {
      isFetching: false,
      noData: false,
      value: 4,
      max: 5,
      survey_answers: [],
      colors: [
        "rgb(234, 72, 77)",
        "rgb(239, 135, 76)",
        "rgb(248, 196, 61)",
        "rgb(159, 205, 53)",
        "rgb(90, 175, 43)",
      ],
      colors_50: [
        "rgb(234, 72, 77, 0.5)",
        "rgb(239, 135, 76, 0.5)",
        "rgb(248, 196, 61, 0.5)",
        "rgb(159, 205, 53, 0.5)",
        "rgb(90, 175, 43, 0.5)",
      ],
      // chartOptions: {
      //   chart: {
      //     width: 380,
      //     type: "donut",
      //   },
      //   labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      // },
    };
  },

  watch: {
    // $route: {
    //   immediate: true,
    //   handler: "onRouteOrFilterChanged",
    // },

    formfilter: {
      deep: true,
      handler: "onRouteOrFilterChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    genColor(v) {
      if (v > 0.0 && v < 1.0) {
        return `rgb(118, 118, 118);`;
      }
      if (v >= 1.0 && v < 2.0) {
        return this.colors[0];
      }
      if (v >= 2.0 && v < 3.0) {
        return this.colors[1];
      }
      if (v >= 3.0 && v < 4.0) {
        return this.colors[2];
      }
      if (v >= 4.0 && v < 5.0) {
        return this.colors[3];
      }
      if (v >= 5.0) {
        return this.colors[4];
      }
    },

    genColor50(v) {
      if (v > 0.0 && v < 1.0) {
        return `rgb(118, 118, 118);`;
      }
      if (v >= 1.0 && v < 2.0) {
        return this.colors_50[0];
      }
      if (v >= 2.0 && v < 3.0) {
        return this.colors_50[1];
      }
      if (v >= 3.0 && v < 4.0) {
        return this.colors_50[2];
      }
      if (v >= 4.0 && v < 5.0) {
        return this.colors_50[3];
      }
      if (v >= 5.0) {
        return this.colors_50[4];
      }
    },

    chartOptions(v) {
      let labels = [];
      let count_answer = 0;
      let colors = ["#5aaf2b", "#9fcd35", "#f8c43d", "#ef874c", "#ea484d"];

      for (let index = 0; index < v.length; index++) {
        const element = v[index];
        count_answer +=
          element.answer && element.answer.length > 0
            ? parseInt(element.answer[0].count_answer)
            : 0;
        labels.push(
          element.question_choice_title ? element.question_choice_title : ""
        );
      }

      if (v.length <= 2) {
        colors = ["#5aaf2b", "#ea484d"];
      }

      if (v.length > 2 && v.length <= 3) {
        colors = ["#5aaf2b", "#f8c43d", "#ea484d"];
      }

      return {
        labels: labels,
        colors: colors,
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: { show: true },
                value: { show: false },
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "14px",
                  fontFamily: "Noto Sans Thai, sans-serif",
                  fontWeight: 400,
                  color: "rgba(7, 9, 25, 0.5)",
                  label: count_answer
                    ? `ผู้ตอบจำนวน ${count_answer} คน`
                    : "ไม่มีผู้ตอบแบบสอบถาม",
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "12px",
          fontFamily: "Noto Sans Thai, sans-serif",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      };
    },

    series(v) {
      // console.log(v);
      let series = [];

      for (let index = 0; index < v.length; index++) {
        const element = v[index];
        series.push(element.answer_percentage ? element.answer_percentage : 0);
      }

      return series;
    },

    async onRouteOrFilterChanged() {
      await this.fetch();
    },

    async fetch() {
      let promise;
      let params = {};
      this.survey_answers = [];

      const { mHeadquarterId, mDivisionId, mStationId, mSchoolId } =
        this.formfilter;

      this.noData = false;
      this.isFetching = true;

      if (mHeadquarterId) {
        this.$set(params, "mHeadquarterId", mHeadquarterId);
      }

      if (mDivisionId) {
        this.$set(params, "mDivisionId", mDivisionId);
      }

      if (mStationId) {
        this.$set(params, "mStationId", mStationId);
      }

      if (mSchoolId) {
        this.$set(params, "mSchoolId", mSchoolId);
      }

      try {
        promise = await Survey.api().getDashboard(params);

        const { data = [] } = promise.response.data;

        if (data && data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let { questions } = element;

            let tmp_questions = await questions.filter(
              (question) => question.question_type !== "text"
            );

            tmp_questions.sort(function (a, b) {
              var question_no_A = a.question_no,
                question_no_B = b.question_no;
              if (question_no_A < question_no_B) return -1;
              if (question_no_A > question_no_B) return 1;
              return 0;
            });

            let tmpQuestionsAll;

            let newQuestions = tmp_questions.filter(function (question) {
              return question.surveyTopicId == null;
            });

            let tmpQuestions = mapKeys(newQuestions, function (o) {
              return o.question_no;
            });

            let newQuestionsHaveSurveyTopic = tmp_questions.filter(function (
              question
            ) {
              return question.surveyTopicId !== null;
            });

            if (newQuestionsHaveSurveyTopic) {
              let groupBy_newQuestionsHaveSurveyTopic = chain(
                newQuestionsHaveSurveyTopic
              )
                .groupBy("survey_topic.survey_topic_no")
                .map((value, key) => ({ survey_topic: key, questions: value }))
                .value();

              let tmpSurveyTopics = mapKeys(
                groupBy_newQuestionsHaveSurveyTopic,
                function (o) {
                  return o.survey_topic;
                }
              );

              tmpQuestionsAll = { ...tmpQuestions, ...tmpSurveyTopics };
            } else {
              tmpQuestionsAll = { ...tmpQuestions };
            }

            // console.log(tmpQuestionsAll);

            // this.questions = { ...tmpQuestionsAll };

            // console.log(
            //   chain(tmp_questions)
            //     .groupBy("surveyTopicId")
            //     .map((value, key) => ({ surveyTopicId: key, questions: value }))
            //     .value()
            // );

            // let newQuestions = tmp_questions.filter(function (question) {
            //   return question.surveyTopicId == null;
            // });

            // let newQuestionsHaveSurveyTopic = tmp_questions.filter(function (
            //   question
            // ) {
            //   return question.surveyTopicId !== null;
            // });

            // console.log(newQuestions);
            // console.log(newQuestionsHaveSurveyTopic);

            this.survey_answers.push({
              ...element,
              questions: tmpQuestionsAll,
            });
          }
        } else {
          this.noData = true;
        }
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงสรุปข้อมูลแบบประเมินได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  created() {
    this.fetch = debounce(this.fetch, 500);
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.text {
  &__title {
    font-size: 0.85rem;
  }
}
</style>
