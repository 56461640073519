var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-4",attrs:{"align-v":"center","align-h":"end"}},[_c('b-col',{attrs:{"cols":"12"}})],1),(_vm.isFetching)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"400px"}})],1)],1):_c('b-row',[(!_vm.noData)?_c('b-col',_vm._l((_vm.survey_answers),function(survey_answer){return _c('b-card',{key:survey_answer.id,staticClass:"mb-5"},[_c('b-row',[_c('h3',{staticClass:"text-center col-12"},[_c('b',[_vm._v(" "+_vm._s(survey_answer.survey_title)+" ")])]),(survey_answer.questions)?_c('div',{staticClass:"row"},_vm._l((survey_answer.questions),function(question){return _c('div',{key:question.id,staticClass:"mt-4",class:question.questions && question.questions[0]
                  ? 'col-12'
                  : 'col-12 col-md-6'},[(question.questions && question.questions[0])?_c('div',{staticClass:"ml-4 row"},[_c('h5',{staticClass:"mb-4 col-12"},[_vm._v(" "+_vm._s(question.questions[0].survey_topic.survey_topic_title)+" ")]),_vm._l((question.questions),function(isQuestionSurveyTopic){return _c('div',{key:isQuestionSurveyTopic.id,staticClass:"col-6 py-3"},[(isQuestionSurveyTopic.question_type == 'rating')?_c('div',{staticClass:"mb-3 mx-4"},[_c('h6',{staticClass:"text__title"},[_vm._v(" "+_vm._s(isQuestionSurveyTopic.question_title)+" ")]),_c('div',{staticClass:"text-black-50 ml-3"},[_c('p',{staticClass:"m-0"},[_vm._v(" ผู้ตอบจำนวน: "+_vm._s(isQuestionSurveyTopic.resAns && isQuestionSurveyTopic.resAns[0] ? isQuestionSurveyTopic.resAns[0].count_answer : 0)+" คน ")]),_c('p',{staticClass:"m-0"},[_vm._v(" ค่าสถิติ: "+_vm._s(isQuestionSurveyTopic.resAns && isQuestionSurveyTopic.resAns[0] ? ((Math.round( isQuestionSurveyTopic.resAns[0].avg_rating * 100 ) / 100) + " ") : "0")+" ")]),_c('p',{staticClass:"m-0"},[_vm._v(" ค่าสถิติร้อยละ: "+_vm._s(isQuestionSurveyTopic.resAns && isQuestionSurveyTopic.resAns[0] ? ((Math.round( (isQuestionSurveyTopic.resAns[0] .avg_rating / 5) * 100 * 100 ) / 100) + " ") : "0")+" ")])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-md-6 col-xl-9 py-2"},[_c('b-progress',{style:(("background-color: " + (_vm.genColor50(
                            isQuestionSurveyTopic.resAns &&
                              isQuestionSurveyTopic.resAns[0]
                              ? isQuestionSurveyTopic.resAns[0].avg_rating
                              : 0
                          )))),attrs:{"height":"23px"}},[_c('b-progress-bar',{style:(("background-color: " + (_vm.genColor(
                              isQuestionSurveyTopic.resAns &&
                                isQuestionSurveyTopic.resAns[0]
                                ? isQuestionSurveyTopic.resAns[0].avg_rating
                                : 0
                            )))),attrs:{"precision":2,"value":isQuestionSurveyTopic.resAns &&
                              isQuestionSurveyTopic.resAns[0]
                                ? isQuestionSurveyTopic.resAns[0].avg_rating
                                : 0,"max":5}})],1)],1),_c('div',{staticClass:"col-12 col-md-6 col-xl-3 py-2",style:(("color: " + (_vm.genColor(
                          isQuestionSurveyTopic.resAns &&
                            isQuestionSurveyTopic.resAns[0]
                            ? isQuestionSurveyTopic.resAns[0].avg_rating
                            : 0
                        ))))},[_c('h5',{staticClass:"m-0"},[_vm._v(" "+_vm._s(isQuestionSurveyTopic.resAns && isQuestionSurveyTopic.resAns[0] ? ((Math.round( isQuestionSurveyTopic.resAns[0] .avg_rating * 100 ) / 100) + " ") : "0")+" ")]),_c('h5',{staticClass:"m-0"},[_vm._v("คะแนน")])])])]):_c('div',{staticClass:"mb-3 ml-4"},[_c('h6',{staticClass:"text__title"},[_vm._v(" "+_vm._s(isQuestionSurveyTopic.question_title)+" ")]),_c('div'),_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"donut","width":"340","height":"340","options":_vm.chartOptions(isQuestionSurveyTopic.choice_detail),"series":_vm.series(isQuestionSurveyTopic.choice_detail)}})],1)])])})],2):_c('div',{staticClass:"ml-4"},[_c('h6',{staticClass:"col-12 mb-3 text__title"},[_vm._v(" "+_vm._s(question.question_title)+" ")]),(question.question_type == 'rating')?_c('div',{staticClass:"mb-3 ml-4"},[_c('div',{staticClass:"text-black-50 ml-3"},[_c('p',{staticClass:"m-0"},[_vm._v(" ผู้ตอบจำนวน: "+_vm._s(question.resAns && question.resAns[0] ? question.resAns[0].count_answer : 0)+" คน ")]),_c('p',{staticClass:"m-0"},[_vm._v(" ค่าสถิติ: "+_vm._s(question.resAns && question.resAns[0] ? ((Math.round( question.resAns[0].avg_rating * 100 ) / 100) + " ") : "0")+" ")]),_c('p',{staticClass:"m-0"},[_vm._v(" ค่าสถิติร้อยละ: "+_vm._s(question.resAns && question.resAns[0] ? ((Math.round( (question.resAns[0].avg_rating / 5) * 100 * 100 ) / 100) + " ") : "0")+" ")])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-10"},[_c('b-progress',{style:(("background-color: " + (_vm.genColor50(
                          question.resAns && question.resAns[0]
                            ? question.resAns[0].avg_rating
                            : 0
                        )))),attrs:{"height":"23px"}},[_c('b-progress-bar',{style:(("background-color: " + (_vm.genColor(
                            question.resAns && question.resAns[0]
                              ? question.resAns[0].avg_rating
                              : 0
                          )))),attrs:{"precision":2,"value":question.resAns && question.resAns[0]
                              ? question.resAns[0].avg_rating
                              : 0,"max":5}})],1)],1),_c('div',{staticClass:"col-2",style:(("color: " + (_vm.genColor(
                        question.resAns && question.resAns[0]
                          ? question.resAns[0].avg_rating
                          : 0
                      ))))},[_c('h5',{staticClass:"m-0"},[_vm._v(" "+_vm._s(question.resAns && question.resAns[0] ? ((Math.round( question.resAns[0].avg_rating * 100 ) / 100) + " ") : "0")+" ")]),_c('h5',{staticClass:"m-0"},[_vm._v("คะแนน")])])])]):_c('div',{staticClass:"mb-3 ml-4"},[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"donut","width":"340","height":"340","options":_vm.chartOptions(question.choice_detail),"series":_vm.series(question.choice_detail)}})],1)])])])}),0):_vm._e()])],1)}),1):_c('b-col',[_c('b-row',{staticStyle:{"height":"520px"},attrs:{"align-v":"center","align-h":"center"}},[_vm._v(" ไม่พบข้อมูล ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }