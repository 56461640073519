<template>
  <div class="rounded">
    <div v-if="userDashboards" class="row">
      <div class="col-sm-12 py-3">
        <h4>จำนวนทั้งหมด {{ totalUsers }} คน</h4>
      </div>
      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-start.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    base.medal ? base.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        base.sum_user_medal ? base.sum_user_medal : ""
                      }}
                      คน</span
                    ></b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-bronze.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    bronze.medal ? bronze.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        bronze.sum_user_medal ? bronze.sum_user_medal : ""
                      }}
                      คน</span
                    ></b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-silver.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    silver.medal ? silver.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        silver.sum_user_medal ? silver.sum_user_medal : ""
                      }}
                      คน</span
                    ></b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 col-xl-3 col-md-6 col-sm-12">
        <div class="card card-box card-box-border-bottom">
          <div class="card-body">
            <div class="align-box-row">
              <div class="text-left">
                <div class="d-flex flex-row align-items-baseline">
                  <div
                    class="mt-1 avatar-icon rounded"
                    style="width: 55px; height: 55px"
                  >
                    <img src="@/assets/img/medal-gold.png" class="rounded" />
                  </div>
                  <b class="font-size-lg text-black pr-1 ml-3">{{
                    gold.medal ? gold.medal : ""
                  }}</b>
                </div>
                <div class="line-height-sm">
                  <b
                    class="font-size-lg text-black pr-1"
                    style="margin-left: 4rem"
                    ><span class="ml-2 text-black-50"
                      >{{
                        gold.sum_user_medal ? gold.sum_user_medal : ""
                      }}
                      คน</span
                    ></b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-card>
      <apexchart
        type="line"
        height="350"
        :options="lineChartRetireYearOptions"
        :series="lineRetireYearSeries"
      />
      <!-- <apexchart
        type="bar"
        height="250"
        :options="chartRetireYearOptions"
        :series="retireYearSeries"
      />

      <apexchart
        type="bar"
        height="250"
        :options="chartNotRetireYearOptions"
        :series="notRetireYearSeries"
      /> -->

      <apexchart
        type="bar"
        height="250"
        :options="chartOptions"
        :series="series"
      />
    </b-card>
  </div>
</template>

<script>
import { chain } from "lodash";
import VueApexCharts from "vue-apexcharts";
import { User } from "../../models";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      totalUsers: 0,
      userDashboards: [],
      userStatusDashboards: [],
      unknownRetireYearCount: 0,
      retireYearDashboards: [],
      base: {
        medal: "ระดับเริ่มต้น",
        sum_user_medal: 0,
      },
      bronze: {
        medal: "ระดับทองแดง",
        sum_user_medal: 0,
      },
      silver: {
        medal: "ระดับเงิน",
        sum_user_medal: 0,
      },
      gold: {
        medal: "ระดับทอง",
        sum_user_medal: 0,
      },
    };
  },

  computed: {
    minRetireYear() {
      const today = new Date();

      return today.getFullYear() + 543;
    },

    maxRetireYear() {
      const { minRetireYear } = this;

      return minRetireYear + 10;
    },

    chartOptions() {
      return {
        chart: {
          offsetX: -10,
          offsetY: -10,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },

        colors: ["#d94c53", "#58b5f5"],

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `${val} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 10,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: ["ไม่เข้าสอน", "เข้าสอน"],
          title: {
            text: "จำนวน",
            rotate: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          labels: {
            show: false,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          title: {
            text: "สถานะ",
            rotate: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: 10,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    lineChartRetireYearOptions() {
      const { retireYearDashboards = [] } = this;

      let categories = ["ไม่ทราบ"];

      const otherYears = chain(retireYearDashboards).map("retire_year").value();

      if (otherYears && otherYears.length) {
        categories = [...categories, ...otherYears];
      }

      return {
        chart: {
          // stacked: true,
          type: "line",
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#d94c53", "#58b5f5"],

        dataLabels: {
          enabled: true,
          // position: "center",
          // enabledOnSeries: [1],
          // enabledOnSeries: [0],
          formatter: function (val) {
            return `${val} คน`;
          },
          background: {
            enabled: true,
            foreColor: "#333",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.6,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#fff", "#fff"],
          },
        },

        stroke: {
          curve: "smooth",
          // colors: ["transparent"],
          // width: 10,
          width: [0, 4],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        // markers: {
        //   size: 6,
        // },

        xaxis: {
          categories,
          title: {
            text: "ปี",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: [
          {
            title: {
              text: "จำนวนครูเกษียณ",
              // rotate: 0,
              // offsetY: -130,
              // offsetX: 30,
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
            },
          },
          {
            opposite: true,
            title: {
              text: "จำนวนครูคงเหลือ",
              // rotate: 0,
              // offsetY: -130,
              // offsetX: 30,
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
                fontFamily: "Noto Sans Thai, sans-serif",
              },
            },
          },
        ],

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },

          style: {
            fontSize: "12px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    chartRetireYearOptions() {
      const { retireYearDashboards = [] } = this;

      let categories = ["ไม่ทราบ"];

      const otherYears = chain(retireYearDashboards).map("retire_year").value();

      if (otherYears && otherYears.length) {
        categories = [...categories, ...otherYears];
      }

      return {
        chart: {
          // stacked: true,
          type: "bar",
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#d94c53"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return `${val} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: [0, 10],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories,
          title: {
            text: "ปี",
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวน (คน)",
            rotate: 0,
            offsetY: -130,
            offsetX: 30,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    chartNotRetireYearOptions() {
      const { retireYearDashboards = [] } = this;

      let categories = ["ไม่ทราบ"];

      const otherYears = chain(retireYearDashboards).map("retire_year").value();

      if (otherYears && otherYears.length) {
        categories = [...categories, ...otherYears];
      }

      return {
        chart: {
          // stacked: true,
          type: "line",
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#58b5f5"],

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return `${val} คน`;
          },
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
            colors: ["#333", "#333"],
          },
        },

        stroke: {
          curve: "smooth",
          // colors: ["transparent"],
          // width: [0, 4],
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories,
          title: {
            text: "ปี",
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวน (คน)",
            rotate: 0,
            offsetY: -130,
            offsetX: 30,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          y: {
            formatter: function (value) {
              return `${value} คน`;
            },
          },

          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    lineRetireYearSeries() {
      let { retireYearDashboards = [], totalUsers = 0 } = this;

      let dataRetire = [this.unknownRetireYearCount];
      let dataNotRetire = [totalUsers];

      retireYearDashboards.forEach((retireYearDashboard) => {
        const countUserRetire = retireYearDashboard.sum_retire_year
          ? parseInt(retireYearDashboard.sum_retire_year)
          : 0;

        const countUserNotRetire = totalUsers - countUserRetire;

        totalUsers -= countUserRetire;

        dataRetire.push(countUserRetire);
        dataNotRetire.push(countUserNotRetire);
      });

      return [
        {
          name: "จำนวนครูเกษียณ",
          type: "column",
          data: dataRetire,
        },
        {
          name: "จำนวนครูคงเหลือ",
          type: "line",
          data: dataNotRetire,
        },
      ];
    },

    retireYearSeries() {
      let { retireYearDashboards = [] } = this;

      let dataRetire = [this.unknownRetireYearCount];
      // let dataNotRetire = [0];

      retireYearDashboards.forEach((retireYearDashboard) => {
        const countUserRetire = retireYearDashboard.sum_retire_year
          ? parseInt(retireYearDashboard.sum_retire_year)
          : 0;

        // const countUserNotRetire = totalUsers - countUserRetire;

        // totalUsers -= countUserRetire;

        dataRetire.push(countUserRetire);
        // dataNotRetire.push(countUserNotRetire);
      });

      return [
        {
          name: "จำนวนครูเกษียณ",
          data: dataRetire,
        },
        // {
        //   name: "จำนวนครูคงเหลือ",
        //   data: dataNotRetire,
        // },
      ];
    },

    notRetireYearSeries() {
      let { retireYearDashboards = [], totalUsers = 0 } = this;

      // let dataRetire = [this.unknownRetireYearCount];
      let dataNotRetire = [0];

      retireYearDashboards.forEach((retireYearDashboard) => {
        const countUserRetire = retireYearDashboard.sum_retire_year
          ? parseInt(retireYearDashboard.sum_retire_year)
          : 0;

        const countUserNotRetire = totalUsers - countUserRetire;

        totalUsers -= countUserRetire;

        // dataRetire.push(countUserRetire);
        dataNotRetire.push(countUserNotRetire);
      });

      return [
        // {
        //   name: "จำนวนครูเกษียณ",
        //   data: dataRetire,
        // },
        {
          name: "จำนวนครูคงเหลือ",
          data: dataNotRetire,
        },
      ];
    },

    // chartOptions() {
    //   const { userStatusDashboards = [] } = this;

    //   return {
    //     chart: {
    //       offsetX: -20,
    //       shadow: {
    //         enabled: true,
    //         color: "#000",
    //         top: 18,
    //         left: 7,
    //         blur: 10,
    //         opacity: 1,
    //       },
    //       toolbar: {
    //         show: false,
    //       },
    //     },

    //     colors: ["#d94c53", "#58b5f5"],

    //     dataLabels: {
    //       enabled: false,
    //     },

    //     stroke: {
    //       curve: "smooth",
    //       colors: ["transparent"],
    //       width: 10,
    //     },

    //     grid: {
    //       borderColor: "#e7e7e7",
    //     },

    //     markers: {
    //       size: 6,
    //     },

    //     xaxis: {
    //       categories: map(userStatusDashboards, "user_status"),
    //       title: {
    //         text: "สถานะ",
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       labels: {
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     yaxis: {
    //       title: {
    //         text: "จำนวน",
    //         rotate: 0,
    //         offsetY: -130,
    //         offsetX: 30,
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //       labels: {
    //         style: {
    //           fontSize: "14px",
    //           fontFamily: "Noto Sans Thai, sans-serif",
    //         },
    //       },
    //     },

    //     legend: {
    //       position: "top",
    //       horizontalAlign: "left",
    //       offsetX: 20,
    //       offsetY: -5,
    //       fontSize: "16px",
    //       fontFamily: "Noto Sans Thai, sans-serif",
    //       markers: {
    //         width: 12,
    //         height: 12,
    //         strokeWidth: 0,
    //         strokeColor: "#fff",
    //         radius: 12,
    //         offsetX: 0,
    //         offsetY: 0,
    //       },
    //     },

    //     tooltip: {
    //       style: {
    //         fontSize: "14px",
    //         fontFamily: "Noto Sans Thai, sans-serif",
    //       },
    //     },
    //   };
    // },

    series() {
      const { userStatusDashboards = [] } = this;

      return userStatusDashboards.map((userStatusDashboard) => {
        return {
          name: userStatusDashboard.user_status,
          data: [
            userStatusDashboard.sum_user_status
              ? parseInt(userStatusDashboard.sum_user_status)
              : 0,
          ],
        };
      });
    },

    // totalUsers() {
    //   const { userStatusDashboards = [] } = this;

    //   return userStatusDashboards.reduce((acc, userStatusDashboard) => {
    //     return acc + parseInt(userStatusDashboard.sum_user_status);
    //   }, 0);
    // },
  },

  methods: {},

  async created() {
    let { response } = await User.api().getDashboard();
    if (response.data) {
      this.totalUsers = response.data.total_users;

      this.userDashboards = [...response.data.data];

      this.userStatusDashboards = [...response.data.user_status];

      this.unknownRetireYearCount = 0;
      this.retireYearDashboards = [];

      if (response.data.user_retire_year) {
        const { user_retire_year = [] } = response.data;

        user_retire_year.forEach((retire_record) => {
          if (retire_record.retire_year === "ไม่ทราบ") {
            this.unknownRetireYearCount = retire_record.sum_retire_year;
          } else {
            this.retireYearDashboards.push({ ...retire_record });
          }
        });

        if (this.retireYearDashboards.length) {
          this.retireYearDashboards = [
            ...chain(
              this.retireYearDashboards.filter(({ retire_year } = {}) => {
                retire_year = parseInt(retire_year);

                return (
                  retire_year >= this.minRetireYear &&
                  retire_year <= this.maxRetireYear
                );
              })
            )
              .orderBy(["retire_year"], ["asc"])
              .value(),
          ];

          // this.retireYearDashboards = [
          //   this.retireYearDashboards[0],
          //   this.retireYearDashboards[1],
          //   this.retireYearDashboards[2],
          //   this.retireYearDashboards[3],
          //   this.retireYearDashboards[4],
          //   this.retireYearDashboards[5],
          //   this.retireYearDashboards[6],
          //   this.retireYearDashboards[7],
          //   this.retireYearDashboards[8],
          //   this.retireYearDashboards[9],
          //   this.retireYearDashboards[10],
          // ];
        }
      }

      for (let index = 0; index < this.userDashboards.length; index++) {
        const userDashboard = this.userDashboards[index];
        if (userDashboard.medal == null) {
          this.$set(
            this.base,
            "sum_user_medal",
            this.base.sum_user_medal + parseInt(userDashboard.sum_user_medal)
          );
        }
        if (userDashboard.medal == "ระดับต้น") {
          this.$set(
            this.base,
            "sum_user_medal",
            this.base.sum_user_medal + parseInt(userDashboard.sum_user_medal)
          );
        }
        if (userDashboard.medal == "ระดับทองแดง") {
          this.$set(
            this.bronze,
            "sum_user_medal",
            this.bronze.sum_user_medal + parseInt(userDashboard.sum_user_medal)
          );
        }
        if (userDashboard.medal == "ระดับเงิน") {
          this.$set(
            this.silver,
            "sum_user_medal",
            this.silver.sum_user_medal + parseInt(userDashboard.sum_user_medal)
          );
        }
        if (userDashboard.medal == "ระดับทอง") {
          this.$set(
            this.gold,
            "sum_user_medal",
            this.gold.sum_user_medal + parseInt(userDashboard.sum_user_medal)
          );
        }
      }
    }
  },
};
</script>
